import React from 'react'
import NoSSR from 'react-no-ssr'
import Layout from '../components/layout'
import SEO from '../components/seo'
import OsmMap from '../components/map'

const Loading = () => <div>Loading...</div>
const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `leaflet`, `react`]} />
    <h1>Leaflet map</h1>
    <div>
      <NoSSR onSSR={<Loading />}>
        <OsmMap />
      </NoSSR>
    </div>
    <p>
      <a href="https://github.com/tormi/gatsby-site">Source</a>
    </p>
  </Layout>
)

export default IndexPage
