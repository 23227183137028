import React, { Component } from 'react'

// See: https://medium.com/@kyleshevlin/how-to-use-client-side-libraries-in-a-universal-javascript-app-aa552b715f06
const isBrowser = typeof window !== 'undefined'
const { Map, TileLayer, Marker, Popup } = isBrowser
  ? require('react-leaflet')
  : () => {}

export default class OsmMap extends Component {
  state = {
    lat: 59.344,
    lng: 24.5904,
    zoom: 13,
    attribution:
      '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  }
  render() {
    const { lat, lng, zoom, attribution, url } = this.state
    return (
      <Map key={Math.random()} center={[lat, lng]} zoom={zoom}>
        <TileLayer attribution={attribution} url={url} />
        <Marker position={[lat, lng]}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </Map>
    )
  }
}
